<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="MonitorIcon"
                  width="24"
                  height="24"
                />
                <h2>Network specifications</h2>
              </div>
              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Network"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="network_ip"
                    rules="required|max:255"
                    vid="network_ip"
                  >
                    <v-select
                      v-model="network"
                      :clearable="false"
                      label="name"
                      placeholder="Select network"
                      :options="networks"
                      is-open
                      open
                      return-object
                      filled
                      :searchable="true"
                      @search="onSearchNetwork"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <p
                      v-if="network"
                      class="input-under-text"
                    >
                      Vlan Interface Address: {{ network.interface_ip }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Name"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required|max:255"
                    vid="name"
                  >
                    <b-form-input
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter unique name"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Network Address"
                  label-class="label-class"
                  class="neighbouring-form-group"
                  disabled
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Network Address"
                    vid="dhcp_address"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="000.000.0.0"
                      autocomplete="off"
                      :disabled="true"
                      :onchange="(value) => form.dhcp_address = value"
                      :model="form.dhcp_address"
                      inputs-id="dhcp_address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Subnet Mask"
                  label-class="label-class"
                  class="neighbouring-form-group"
                  disabled
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Subnet Mask"
                    vid="subnet_mask"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="000.000.0.0"
                      autocomplete="off"
                      :disabled="true"
                      :onchange="(value) => form.subnet_mask = value"
                      :model="form.subnet_mask"
                      inputs-id="subnet_mask"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Gateway IP"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Gateway IP"
                    vid="gateway_ip"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter gateway IP address"
                      autocomplete="off"
                      :onchange="(value) => form.gateway_ip = value"
                      :model="form.gateway_ip"
                      inputs-id="gateway_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="WiFi Controller IP"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="WiFi Controller IP"
                    rules="max:255"
                    vid="wifi_controller_ip"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter the WiFi Controller IP"
                      autocomplete="off"
                      :onchange="(value) => form.wifi_controller_ip = value"
                      :model="form.wifi_controller_ip"
                      inputs-id="wifi_controller_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="DNS Server IP"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="DNS Server IP"
                    vid="dns1_ip"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter DNS server IP address"
                      autocomplete="off"
                      :onchange="(value) => form.dns1_ip = value"
                      :model="form.dns1_ip"
                      inputs-id="dns1_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="DNS Server IP #2"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="DNS Server IP #2"
                    vid="dns2_ip"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter second DNS server IP address"
                      autocomplete="off"
                      :onchange="(value) => form.dns2_ip = value"
                      :model="form.dns2_ip"
                      inputs-id="dns2_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="NTP Server IP"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NTP Server IP"
                    vid="ntp1_ip"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter NTP server IP address"
                      autocomplete="off"
                      :onchange="(value) => form.ntp1_ip = value"
                      :model="form.ntp1_ip"
                      inputs-id="ntp1_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="NTP Server IP #2"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NTP Server IP #2"
                    vid="ntp2_ip"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter second NTP server IP address"
                      autocomplete="off"
                      :onchange="(value) => form.ntp2_ip = value"
                      :model="form.ntp2_ip"
                      inputs-id="ntp2_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="IP Pool Start"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="IP Pool Start"
                    vid="ip_pool_start"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter IP pool start address"
                      autocomplete="off"
                      :onchange="(value) => form.ip_pool_start = value"
                      :model="form.ip_pool_start"
                      inputs-id="ip_pool_start"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="IP Pool End"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="IP Pool End"
                    vid="ip_pool_end"
                    rules="max:255"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter IP pool end address"
                      autocomplete="off"
                      :onchange="(value) => form.ip_pool_end = value"
                      :model="form.ip_pool_end"
                      inputs-id="ip_pool_end"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <h6 class="mt-1 mb-1">
                Lease Time
              </h6>
              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Days"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lease Time Days"
                    vid="lease_time"
                    rules="max:255"
                  >
                    <v-select
                      v-model="form.lease_time_days"
                      :clearable="false"
                      label="title"
                      placeholder="Select days amount"
                      :options="Array.from(Array(32).keys()).map(el => el.toString())"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <span class="date-double-dots">:</span>
                <b-form-group
                  label="Hours"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lease Time Hours"
                    vid="lease_time_hours"
                    rules="max:255"
                  >
                    <v-select
                      v-model="form.lease_time_hours"
                      :clearable="false"
                      label="title"
                      :options="Array.from(Array(24).keys()).map(el => el.toString())"
                      placeholder="Select hours amount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <span class="date-double-dots">:</span>
                <b-form-group
                  label="Minutes"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lease Time Minutes"
                    vid="lease_time_minutes"
                    rules="max:255"
                  >
                    <v-select
                      v-model="form.lease_time_minutes"
                      :clearable="false"
                      placeholder="Select minutes"
                      label="title"
                      :options="Array.from(Array(60).keys()).map(el => el.toString())"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div
                class="neighbouring-form-container mt-1"
              >
                <b-form-group
                  label="Domain Name"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Domain Name"
                    rules="max:255"
                    vid="domain_name"
                  >
                    <b-form-input
                      v-model="form.domain_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter domain name"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Create"
                :request="validationForm"
              />
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormIp,
    BForm,
    BRow,
    vSelect,
    BCol,
    BButton,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      form: {
        name: '',
        vlan_id: '',
        interface_ip: '',
        subnet_mask: '',
        comment: '',
        lease_time_days: '1',
        lease_time_hours: '0',
        lease_time_minutes: '0',

        network_ip: '',
        gateway_ip: '',
        dns1_ip: '',
        dns2_ip: '',
        ntp1_ip: '',
        ntp2_ip: '',
        wifi_controller_ip: '',
        ip_pool_start: '',
        ip_pool_end: '',
        domain_name: '',
      },

      network: null,
      networks: [],
      searchNetwork: '',

      required,
      max,
    }
  },
  watch: {
    network(networkValue) {
      if (networkValue) {
        this.form = {
          ...this.form,
          network_ip: networkValue.id,
          gateway_ip: networkValue.interface_ip,
          dns1_ip: networkValue.interface_ip,
          ntp1_ip: networkValue.interface_ip,
          wifi_controller_ip: networkValue.interface_ip,
          subnet_mask: networkValue.subnet_mask,
          name: networkValue.name,
          ip_pool_end: networkValue.pool_end_ip,
          ip_pool_start: networkValue.pool_start_ip,
          dhcp_address: networkValue.dhcp_address,
        }
      }
    },
    searchNetwork(search) {
      this.getNetworks(search)
    },
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  created() {
    this.getNetworks()
    this.setSiteName(this.$route.params.id)
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async setSiteName(id) {
      try {
        if (id) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          const { data: { data } } = await axios.get(`/site/${id}/show`)

          const link = document.createElement('a')
          link.href = `/site/${id}/hardware/index`
          link.innerHTML = `(${data.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    async getNetworks() {
      try {
        const { data } = await axios.get(`/site/${this.$route.params.id}/network/setting/lan/index?page=1${this.searchNetwork ? `&search=${this.searchNetwork}` : ''}`)

        if (Array.isArray(data.data)) {
          this.networks = data.data
        } else {
          this.networks = []
        }
      } catch (error) {
        console.error(error)
      }
    },
    onSearchNetwork(search) {
      this.searchNetwork = search
    },
    async validationForm() {
      let valid = await this.$refs.modelsCreate.validate()

      if ([this.form.lease_time_days, this.form.lease_time_hours, this.form.lease_time_minutes].every(num => num === '0')) {
        this.$refs.modelsCreate.setErrors({
          lease_time: 'The lease time is required',
        })
        valid = false
      } else {
        this.$refs.modelsCreate.setErrors({
          lease_time: '',
        })
      }

      if (valid) {
        try {
          const formatTime = (time = 0) => (time < 10 ? `0${time}` : time)

          const formatted = `${this.form.lease_time_days ? `${this.form.lease_time_days}d ` : ''}${formatTime(+this.form.lease_time_hours || 0)}:${formatTime(+this.form.lease_time_minutes || 0)}:00`

          const payload = {
            ...this.form,
            lease_time: formatted === '00:00:00' ? null : formatted,
            lan_id: this.network.id,
          }
          await axios.post(`/site/${this.$route.params.id}/network/dhcp-server`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.goBack()
        } catch (error) {
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.modelsCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
